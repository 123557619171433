body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


table {
  white-space: nowrap;
}

.MuiPaper-root {
  width: 100%;
  overflow-x: auto;
}
